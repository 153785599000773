<template>
  <div class="pt-1">
    <v-form
    class="pt-1 campaign-form"
    lazy-validation
    ref="roles_form"
    v-model="valid"
  >
    <v-breadcrumbs :items="items"></v-breadcrumbs>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="8">
        <general-card class="pa-5 mt-5 three-quarter-height">
          <h6 class="mb-4">Role Name</h6>
          <v-text-field
            label="Name"
            dense
            outlined
            class="mb-4"
            v-model="name"
          ></v-text-field>
          <h6 class="mb-4">Permissions</h6>
          <v-combobox
            label="Assign Permissions"
            multiple
            :items="permissions"
            item-text="name"
            item-value="id"
            v-model="role_permissions"
            :rules="[(v) => !!v || 'Select permission to continue!']"
            class="mb-12"
            dense
            outlined
            deletable-chips
            small-chips
            clearable
            chips
          ></v-combobox>
          <v-card-actions class="d-flex justify-end">
            <v-btn
              color="primary"
              small
              @click="editRole"
              v-if="$route.name == 'update-role'"
              >Update</v-btn
            >
            <v-btn color="primary" small @click="addRole" v-else>Submit</v-btn>
          </v-card-actions>
        </general-card>
      </v-col>
    </v-row>
    </v-form>
  </div>
</template>

<script>
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    valid: true,
    name: "",
    role_permissions: null,
    items: [
      {
        text: "Roles",
        disabled: false,
        href: "#/users-management/roles",
      },
      {
        text: "Add Role",
        disabled: true,
        href: "#",
      },
    ],
  }),
  created() {
    if (this.$route.name == "update-role") {
      this.getRoleData();
    }
  },
  computed: {
    single_role() {
      return this.$store.state.role;
    },
    permissions() {
      if (!this.$store.state.permissions.length) {
        this.getPermissions();
      }
      return this.$store.state.permissions;
    },
  },
  watch: {
    single_role() {
      this.name = this.single_role.name;
      this.role_permissions = this.single_role.permissions;
    },
  },
  components: {
    GeneralCard,
  },
  methods: {
    async addRole() {
      await this.$refs.roles_form.validate();
      if (!this.name ||!this.valid ||!this.role_permissions ) return;
      let assigned_permissions = this.role_permissions.map(function (permission) {
          return permission.id;
      });
      let body= {
          name: this.name,
          permissions: assigned_permissions,
        };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("addNewRole", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Role added successfully!",
          group: "success",
        });

        this.$router.push("/users-management/roles");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async editRole() {
      await this.$refs.roles_form.validate();
      if (!this.name ||!this.valid ||!this.role_permissions ) return;
      let assigned_permissions = this.role_permissions.map(function (permission) {
          return permission.id;
      });
      let combined_data = {
        role_id: this.$route.params.id,
        body: {
          name: this.name,
          permissions: assigned_permissions,
        },
      };
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("updateRole", combined_data);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Role updated successfully!",
          group: "success",
        });

        this.$router.push("/users-management/roles");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async getRoleData() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("getSingleRole", this.$route.params.id);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    //Get Permissions
    async getPermissions() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readPermissions");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>